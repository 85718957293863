import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from 'react-router-dom';
import { allShortListed } from "../apis/AdvertsFunction";
import ShortList from "../features/ShortList";
import LoadingSpinner from "../components/LoadingSpinner";
import PageTitle from "../components/PageTitle";
import ShortlistedTable from "../components/ShortlistedTable";
import "./App.css";


const ShortListing = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [searchQuery, setSearchQuery] = useState('');

  const [timer, setTimer] = useState()

  const { isLoading, isError, error, data: shortlisted } = useQuery({
    queryKey: ['shortlisted'],
    queryFn: allShortListed
  });

  console.log(shortlisted);

  const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
    if (shortlisted && shortlisted.length > 0) {
        setTotalPages(Math.ceil(shortlisted.length / 10));
    }
    }, [shortlisted]);

    const handlePrevPage = () => {
    if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
    }
    };

    const handleNextPage = () => {
    if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
    }
    };



  // conditions
  if (isLoading) return <p><LoadingSpinner /></p>

  if (isError) return <p>Error: {error.message}</p>


  //console.log("list", shortlisted)

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = shortlisted.filter((person) =>
    person.title.toLowerCase().includes(searchQuery.toLowerCase())
  );


  return (
    <>
      <div class="mx-auto max-w-screen-xl ">
        <h1 class="flex mt-10 mb-4 ml-5 text-3xl font-bold text-gray-900">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-8">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
</svg>
            <p className="ml-2">Shortlist of All Applicants</p></h1>
       
      </div>



      <div class="w-screen bg-gray-50">
        <div class="mx-auto max-w-screen-xl px-2 py-10">
          <div class="mt-4 w-full">
            <div class="flex w-full flex-col items-center justify-between space-y-2 sm:flex-row sm:space-y-0">
              <form class="relative flex w-full max-w-2xl items-center">
                <svg class="absolute left-2 block h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <circle cx="11" cy="11" r="8" class=""></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65" class=""></line>
                </svg>
                <input type="text" name="search" placeholder="Search by Advert ..."
            value={searchQuery}
            onChange={handleSearch} class="h-12 w-full rounded-xl border-b-gray-400 bg-transparent py-4 pl-12 text-sm outline-none focus:border-b-2" />
              </form>
      
              <Link to="/adverts-list" class="relative mr-auto inline-flex cursor-pointer items-center rounded-full border border-gray-200 bg-blue-600 px-5 py-2 text-center text-sm font-medium text-white hover:bg-blue-700 focus:shadow sm:mr-0">
                <span class="absolute top-0 right-0 h-2 w-2 rounded-full bg-red-500 font-semibold"></span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z" />
              </svg>

      
                Go To Adverts
              </Link>
            </div>
          </div>
      
          <div class="mt-6 overflow-hidden rounded-xl bg-white px-6 shadow lg:px-4">



          {filteredData.length === 0 ? (
                            <div className="p-4 font-semibold text-white bg-green-400">
                                No Data Available Yet!.
                            </div>
                        ) : (
                            <table class="min-w-full border-collapse border-spacing-y-2 border-spacing-x-2">
                                <thead class="hidden border-b lg:table-header-group">
                                    <tr>

                                        <td class="whitespace-normal py-4 text-sm font-semibold text-gray-800 sm:px-3"><h6 className='ml-2 mr-1 font-semibold'>Name</h6></td>
                                            <td class="whitespace-normal py-4 text-sm font-semibold text-gray-800 sm:px-3"><h6 className='ml-2 mr-1 font-semibold'>Surname</h6></td>
                                            <td class="whitespace-normal py-4 text-sm font-semibold text-gray-800 sm:px-3"><h6 className='ml-2 mr-1 font-semibold'>Gender</h6></td>

                                            <td class="whitespace-normal py-4 text-sm font-semibold text-gray-800 sm:px-3"><h6 className='ml-2 mr-1 font-semibold'>National ID</h6></td>

                                            <td class="whitespace-normal py-4 text-sm font-semibold text-gray-800 sm:px-3"><h6 className='ml-2 mr-1 font-semibold'>Contact</h6></td>
                                            <td class="whitespace-normal py-4 text-sm font-semibold text-gray-800 sm:px-3"><h6 className='ml-2 mr-1 font-semibold'>Advert</h6></td>


                                            <td class="whitespace-normal py-4 text-sm font-bold text-gray-800 sm:px-3">
                                            <div className='flex'>
                                            <h6 className='ml-2 mr-1 font-semibold'>Total Score</h6>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                                </svg></div>

                                 </td>
                                    </tr>
                                </thead>
                                <tbody class="bg-white lg:border-gray-300">
                                    {filteredData.map((person, index) => (
                                         <tr key={index}>
                                         <td class="whitespace-no-wrap hidden py-4 text-sm font-medium text-gray-600 sm:px-3 lg:table-cell">{person.firstnames}</td>
                                         <td class="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">{person.surname}</td>
                                         <td class="whitespace-no-wrap hidden py-4 text-sm font-norma text-gray-600 sm:px-3 lg:table-cell">{person.sex}</td>
                                         <td class="whitespace-no-wrap hidden py-4 text-sm font-norma text-gray-600 sm:px-3 lg:table-cell">{person.national_id}</td>
                                         <td class="whitespace-no-wrap hidden py-4 text-sm font-norma text-gray-600 sm:px-3 lg:table-cell">{person.cell}</td>
                                         <td class="whitespace-no-wrap hidden py-4 text-sm font-norma text-gray-600 sm:px-3 lg:table-cell">{person.title}</td>
                                         <td class="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-500 sm:px-3 lg:table-cell">
                                    <button disabled class="ml-2 mr-3 whitespace-nowrap rounded-full bg-blue-100 px-3 py-2 text-blue-800 hover:bg-blue-600 hover:text-white ">{person.totalscore}</button>
                                    </td>
                                       
                                     </tr>
                                    ))}
                                     </tbody>
                            </table>
                        )}



          </div>
        </div>
      </div>


    </>
  );
}

export default ShortListing;
