import React from 'react'
import { useQuery } from "@tanstack/react-query";
import { fetchAdverts } from "../apis/AdvertsFunction";
import VacancyList from "../features/VacancyList";
import { useState, useEffect } from "react";
import LoadingSpinner from "../components/LoadingSpinner";
import PageTitle from "../components/PageTitle";
import PageButton from "../components/PageButton";
import { Link } from "react-router-dom";
import { useGetAdvertsQuery, advertsApi } from "../apis/advertsApi";
import AdvertSpinner from "../components/AdvertSpinner";

function TestPage() {
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState("")

  //fetch adverts from provided endpoint
  const { data, isFetching, error, isLoading, refetch } = useGetAdvertsQuery();
  //console.log(data);
  const adverts = data?.data;

  const filteredAdverts = adverts?.filter((advert) => {
    const closingDate = new Date(advert.closing_date);
    const today = new Date();

    // Reset the time part of today to 00:00:00 for proper comparison
    //today.setHours(0, 0, 0, 0);

    const isClosingToday = closingDate.toDateString() === today.toDateString();

    return advert.title.toLowerCase().includes(searchTerm.toLowerCase()) && (isClosingToday || closingDate > today);
  });

  const handleSearch = async (e) => {
    e.preventDefault();
    await advertsApi.invalidateQueries('getAdverts', { refetchActive: true }); // Invalidate the 'getAdverts' query to trigger a refetch
  };


  const handleNetworkError = async () => {
    await refetch(); // This will trigger a refetch of the adverts data
  };


  if (isLoading) return <p><LoadingSpinner /></p>

  if (error) return <div className="flex justify-center align-middle bg-green-400">
  <p className="font-bold text-gray-800">Oops! Please check your internet connection and try again. .</p>
  <button className="font font-semibold bg-blue-600" onClick={handleNetworkError}>Click to Retry</button>
</div>

  return (
    <div className=''>
        <div className="container mx-auto">
  <section className="mx-auto mt-1 mb-5" >
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="card-body">

        <blockquote className="relative bg-white px-3 pt-4 text-lg">
          <div className="bg-primary shadow-2xl w-12 h-12 rounded-full flex items-center justify-center absolute -top-10 -left-2">
            <i className="fa fa-quote-left text-white"></i>
          </div>

          <h2 className="text-2xl font-bold">How To Apply</h2>
          <p className="mt-2 italic">
            <ol className="list-decimal pl-5">
              <li>View Advert</li>
              <li>Click Apply Button</li>
              <li>Fill in the Personal Details, Academic Qualifications and Work Experience Details.</li>
              <li>Send Application</li>
            </ol>
          </p>
          <footer className="pt-4 mt-4 border-t">
            Get Started with our Platform
            <cite title="Source Title">Today</cite>
          </footer>
        </blockquote>
      </div>
    </div>
  </section>
</div>

{/* <div className="">
    <div className="container flex justify-center items-center px-4 sm:px-6 lg:px-8">
        <div className="relative">

            <input type="text" className="h-14 w-96 pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none" placeholder="Search Adverts..." />

            <div className="absolute top-4 right-3">
                <i className="fa fa-search text-gray-400 z-20 hover:text-gray-500"></i>
            </div>

        </div>
    </div>
</div> */}

<div className='flex justify-center'>
<form onSubmit={handleSearch} className="mx-2 mb-2 max-w-xl sm:rounded-full sm:border sm:border-gray-100 sm:bg-white sm:p-2 sm:shadow">
  <div className="flex flex-col gap-2 sm:flex-row sm:items-center sm:justify-between">
    <div className="relative text-gray-500 sm:w-7/12">
      
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        
      </div>
      <input type="text" name="search" id="search" onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search Adverts" className="w-full cursor-text rounded-full border-2 px-4 py-3 pr-4 pl-10 text-base outline-none transition-all duration-200 ease-in-out sm:border-0 focus:border-transparent focus:ring" required="" />
    </div>

    <button disabled className="group flex items-center justify-center rounded-full bg-blue-700 px-6 py-3 text-white transition">
      <span className="group flex w-full items-center justify-center whitespace-nowrap rounded text-center font-medium"> Search </span>
      <svg className="shrink-0 group-hover:ml-8 ml-4 h-4 w-4 transition-all" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
      </svg>
    </button>
  </div>
</form>
</div>

    <div>
          {filteredAdverts &&
            filteredAdverts.map((advert) => (
              <VacancyList advert={advert} key={advert.id} />
            ))}
        </div>





    </div>
  )
}

export default TestPage