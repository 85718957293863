import React from 'react'
<link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;1,600&display=swap" rel="stylesheet" />

function TailwindTest() {
  return (
    <div> 
      

<div class="mx-auto max-w-screen-xl ">
  <h1 class="mt-10 mb-4 ml-5 text-2xl font-bold text-gray-900">Advert Title</h1>
 
</div>
<div class="w-screen bg-gray-50">
  <div class="mx-auto max-w-screen-xl px-2 py-10">
    <div class="mt-4 w-full">
      <div class="flex w-full flex-col items-center justify-between space-y-2 sm:flex-row sm:space-y-0">
        <form class="relative flex w-full max-w-2xl items-center">
          <svg class="absolute left-2 block h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <circle cx="11" cy="11" r="8" class=""></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65" class=""></line>
          </svg>
          <input type="name" name="search" class="h-12 w-full border-b-gray-400 bg-transparent py-4 pl-12 text-sm outline-none focus:border-b-2" placeholder="Type here to Search Applicant" />
        </form>

        <button type="button" class="relative mr-auto inline-flex cursor-pointer items-center rounded-full border border-gray-200 bg-blue-600 px-5 py-2 text-center text-sm font-medium text-white hover:bg-blue-700 focus:shadow sm:mr-0">
          <span class="absolute top-0 right-0 h-2 w-2 rounded-full bg-red-500 font-semibold"></span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
        </svg>

          Summary Table
        </button>
      </div>
    </div>

    <div class="mt-6 overflow-hidden rounded-xl bg-white px-6 shadow lg:px-4">
      <table class="min-w-full border-collapse border-spacing-y-2 border-spacing-x-2">
        <thead class="hidden border-b lg:table-header-group">
          <tr class="">

            <td class="whitespace-normal py-4 text-sm font-semibold text-gray-800 sm:px-3"><h6 className='ml-2 mr-1 font-semibold'>Name</h6></td>
            <td class="whitespace-normal py-4 text-sm font-semibold text-gray-800 sm:px-3"><h6 className='ml-2 mr-1 font-semibold'>Surname</h6></td>
            <td class="whitespace-normal py-4 text-sm font-semibold text-gray-800 sm:px-3"><h6 className='ml-2 mr-1 font-semibold'>Gender</h6></td>

            <td class="whitespace-normal py-4 text-sm font-semibold text-gray-800 sm:px-3"><h6 className='ml-2 mr-1 font-semibold'>Email</h6></td>
            <td class="whitespace-normal py-4 text-sm font-semibold text-gray-800 sm:px-3"><h6 className='ml-2 mr-1 font-semibold'>National ID</h6></td>

            <td class="whitespace-normal py-4 text-sm font-semibold text-gray-800 sm:px-3"><h6 className='ml-2 mr-1 font-semibold'>Contact</h6></td>

            <td class="whitespace-normal py-4 text-sm font-bold text-gray-800 sm:px-3">
              <div className='flex'>
              <h6 className='ml-2 mr-1 font-semibold'>Action</h6>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
</svg></div>

            </td>
          </tr>
        </thead>

        <tbody class="bg-white lg:border-gray-300">
          <tr class="">

            <td class="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">62345231143</td>

            <td class="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">Desktop Computer</td>

            <td class="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-600 sm:px-3 lg:table-cell">
             Male
            </td>
            <td class="whitespace-no-wrap hidden py-4 text-left text-sm text-gray-600 sm:px-3 lg:table-cell lg:text-left">Jane Doeson</td>
            <td class="whitespace-no-wrap hidden py-4 text-left text-sm text-gray-600 sm:px-3 lg:table-cell lg:text-left">24 x 10 x 5 cm</td>
            <td class="whitespace-no-wrap hidden py-4 text-left text-sm text-gray-600 sm:px-3 lg:table-cell lg:text-left">1.0 Kg</td>
            

            <td class="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-500 sm:px-3 lg:table-cell">
              <span class="ml-2 mr-3 cursor-pointer whitespace-nowrap rounded-full bg-blue-100 px-3 py-2 text-blue-800 hover:bg-blue-600 hover:text-white ">View Applicant</span>
            </td>
          </tr>
          
        </tbody>
      </table>
    </div>
  </div>
</div>

    </div>
  )
}

export default TailwindTest