import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo1.png';

const VacancyList = ({ advert }) => {
  return (
    <>
      <div className='container mx-auto'>
        <Link to={`/applicant-vacancy/${advert.id}`} className=''>
          <article className="mx-4 my-4 rounded-md bg-white border cursor-pointer border-gray-200 text-gray-800 shadow-md hover:shadow-lg md:mx-auto transition-transform duration-300 ease-in-out transform-gpu hover:-translate-y-2">
            <div className="flex flex-col md:flex-row">
              <div className="p-4 md:w-5/6 md:p-6">
                <span className="rounded-md bg-yellow-400 px-3 py-1 text-sm uppercase text-white">Midlands State University</span>
                <p className="mt-2 text-lg font-semibold md:mt-3 md:text-2xl">{advert.title}</p>
                <div className='flex'>
                  <p className="flex mt-3 mr-3 text-gray-700 font-semibold">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="h-6 w-6 mr-1">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                    Posted: {advert.human_created_at}
                  </p>
                  <p className="mt-3 flex text-gray-700 font-semibold">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="h-6 w-6 mr-1">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                    Expires: {advert.human_closing_date}
                  </p>
                </div>
                <button className="mt-4 mr-2 flex items-center justify-center rounded-md bg-blue-500 px-5 py-2 text-center text-white duration-150 md:mb-4 hover:translate-y-1 hover:bg-blue-600">View Advert</button>
              </div>
              <div className="mx-auto hidden items-center px-4 md:flex md:p-6">
                <img className="rounded-xl shadow-md w-16 h-16 md:w-40 md:h-40" src={logo} alt="Logo" /> 
              </div>
            </div>
          </article>
        </Link>
      </div>
    </>
  );
}

export default VacancyList;