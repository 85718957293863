import React, { useRef } from 'react';
import { Container, Table, Card, Spinner, Alert } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

const AdminSummary = React.forwardRef((props, ref) => {
    const { id } = useParams(); // Use destructuring to get id directly
    const navigate = useNavigate();
    const componentRef = useRef();

    // Define the URL for fetching the list of applicants
    const url = `https://hrm.msu.ac.zw/api/v2/advert-applicants/${id}`;

    // Use React Query to fetch the list of applicants
    const { data, isLoading, error } = useQuery(
        ['applicants', id], // Include id as a dependency
        async () => {
            const response = await axios.get(url);
            return response.data;
        },
    );

    // Check if data exists before destructuring
    const advert = data?.advert;
    const applications = data?.applications;

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: '@page { size: landscape; }'
    });

    // Display loading spinner while data is being fetched
    if (isLoading) {
        return (
            <div className="d-flex justify-content-center mt-4">
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }

    // Display error message if data fetching fails
    if (error) {
        return <Alert variant="danger" className="text-center">Error fetching applicants</Alert>;
    }

    // Check if applicants is an array, otherwise adjust accordingly
    const applicants = Array.isArray(applications) ? applications : applications?.applicants || [];

    // If applicants is not an array after these checks, show an alert
    if (!Array.isArray(applicants)) {
        return <Alert variant="danger" className="text-center">No applicants found</Alert>;
    }

    return (
        <Container className="mt-4">
            <button className="btn btn-outline-primary my-3" onClick={handlePrint}>
                Print Summary Table
            </button>
            <Card className="shadow-sm mb-4" ref={componentRef}> {/* Attach ref here */}
                <Card.Header className="bg-primary text-white fw-bold">Applicants Summary Table</Card.Header>
                <Card.Body>
                <h5 className="text-center fw-bold">{advert || 'Advert Title'}</h5> {/* Display the advert title */}
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>Full Name</th>
                                <th>Gender</th>
                                <th>DOB</th>
                                <th>Experiences</th>
                                <th>Qualifications</th>
                                <th>Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {applicants?.map((applicant, index) => {
                                const experiences = JSON.parse(applicant.experiences || '[]');
                                const qualifications = JSON.parse(applicant.qualifications || '[]');

                                return (
                                    <tr key={index}>
                                        <td>{applicant.firstname}{" "}{applicant.surname}</td>
                                        <td>{applicant.gender}</td>
                                        <td>{applicant.dob}</td>
                                        <td>
                                            <ul>
                                                {experiences?.map((exp, i) => (
                                                    <li key={i}>
                                                        <b>{exp.company}</b>
                                                        <br />
                                                        {exp.jobTitle}, {exp.location}
                                                        <br />
                                                        ({exp.startDate} to {exp.endDate || 'Present'})
                                                    </li>
                                                ))}
                                            </ul>
                                        </td>
                                        <td>
                                            <ul>
                                                {Array.isArray(qualifications) ? (
                                                    qualifications.map((qual, i) => (
                                                        <li key={i}>
                                                            <b>{qual.institution}</b>
                                                            <br />
                                                            {qual.qualification},
                                                            <br />
                                                            <b>Grade:</b> {qual.grade}
                                                            <br />
                                                            ({qual.startDate} to {qual.endDate})
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li>No qualifications available</li> // Fallback if qualifications is not an array
                                                )}
                                            </ul>
                                        </td>
                                        <td>
                                            <textarea className="form-control" rows="7" cols={30}></textarea>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Container>
    );
});

// Export the wrapped component
export default AdminSummary;
