import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dashboard, Assignment, FormatListNumbered, Menu, Close, Logout } from '@mui/icons-material';
import './topbar.css';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { logout } from '../../features/users/usersSlice';

export default function TopBar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/vacancies');
    toast.success("Logout success");
    setMenuOpen(false); // Close menu on logout
  };

  const isAuthenticated = useSelector((state) => state.users.isAuthenticated);
  const userinfo = useSelector((state) => state.users);
  const username = userinfo.user?.user.email;
  const roles = userinfo.user?.roles || [];

  // Role Checks
  const isAdmin = roles.some(role => role.name === "admin");
  const isApplicant = roles.some(role => role.name === "user");

  

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo mb-2">
          <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="MSU Recruitment System" className="navbar-logo-img" />
        </Link>
        <div className="menu-icon" onClick={toggleMenu}>
          {menuOpen ? <Close /> : <Menu />}
        </div>
        <ul className={`nav-menu ${menuOpen ? 'active' : ''}`}>
          {!isAuthenticated ? (
            <>
              <li className="nav-item">
                <Link to="/vacancies" className="nav-links" onClick={() => setMenuOpen(false)}>
                  Vacancies
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link to="/login" className="nav-links" onClick={() => setMenuOpen(false)}>
                  Login
                </Link>
              </li> */}
            </>
          ) : (
            <>
              {isAdmin && (
                <>
                  <li className="nav-item">
                    <Link to="/adverts-list" className="nav-links" onClick={() => setMenuOpen(false)}>
                      <Dashboard className="nav-icon" />
                      Adverts
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/new-advert" className="nav-links" onClick={() => setMenuOpen(false)}>
                      <Assignment className="nav-icon" />
                      Create Advert
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/short-listing" className="nav-links" onClick={() => setMenuOpen(false)}>
                      <FormatListNumbered className="nav-icon" />
                      Shortlisted
                    </Link>
                  </li>
                </>
              )}
              {isApplicant && (
                <>
                  <li className="nav-item">
                    <Link to="/applicant-info" className="nav-links" onClick={() => setMenuOpen(false)}>
                      My Profile
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link to="/my-applications" className="nav-links" onClick={() => setMenuOpen(false)}>
                      My Applications
                    </Link>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link to="/applicant-adverts-list" className="nav-links" onClick={() => setMenuOpen(false)}>
                      View Vacancies
                    </Link>
                  </li> */}
                </>
              )}
              <li className="nav-item">
                <span className="nav-links">
                  {username}
                </span>
              </li>
              <li className="nav-item">
                <Link to="#" className="nav-links" onClick={handleLogout}>
                  <Logout className="nav-icon" />
                  Logout
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
}
